import { useState } from 'react';
import ConnectButton from './ConnectButton';
import {Row,Col,Button} from 'react-bootstrap';
import '../App.css';
import {Link, useNavigate} from "react-router-dom";
import * as React from "react";
import {FiMenu, FiX} from "react-icons/fi";
import ConnectButtonHeaderMobile from "./ConnectButtonHeaderMobile";
import SelectNetwork from "./SelectNetwork";

export default function Header(props) {

    const navigate = useNavigate();

    const [hov1, setHov1] = useState(false);
    const [hov2, setHov2] = useState(false);

    const handleSetWeb3 = (w,a) => props.setWeb3(w,a);

  return (
      <>
          <div className={"d-none d-sm-block"}>
              <Menu web3={props.web3}
                    account={props.account}
                    setWeb3={handleSetWeb3}
                    setNetwork={(n) => props.setNetwork(n)}
              />
          </div>
          <div className={'d-sm-none'}>
              <MobileMenu web3={props.web3}
                          account={props.account}
                          setWeb3={handleSetWeb3}
                          setNetwork={(n) => props.setNetwork(n)}
              />
          </div>
      </>
  );
}

function Menu(props) {

    const navigate = useNavigate();

    const [hov1, setHov1] = useState(false);
    const [hov2, setHov2] = useState(false);

    const setWeb3 = (w,a) => props.setWeb3(w,a);

    return (
        <>
            {/*
            <div className={'Menu p-3 d-flex align-items-center justify-content-between'}>
                <div className='d-flex align-items-center'>
                    <iframe src="https://giphy.com/embed/htSM5MPkwlKaSYE7yN" width="50" height="50" frameBorder="0"
                            className="giphy-embed" allowFullScreen></iframe>
                    <p
                        style={{margin:10,color:'white', fontSize:'20px', fontWeight:'bold'}}
                        onClick={() => navigate("/")}
                    >
                        EclipseNFT
                    </p>
                </div>
                <div className='d-flex align-items-center justify-content-center'>
                    <p onMouseEnter={ () => setHov1(true) }
                       onMouseLeave={ () => setHov1(false) }
                       style={(hov1)? hovStyle : normalStyle }
                       onClick={() => navigate("/artist")}
                    >
                        Creators
                    </p>

                    <p onMouseEnter={ () => setHov2(true) }
                       onMouseLeave={ () => setHov2(false) }
                       style={(hov2)? hovStyle : normalStyle }
                       onClick={() => navigate("/fan")}
                    >
                        Fans
                    </p>
                </div>
                <div className='d-flex align-items-center justify-content-end'>


                    <SelectNetwork style={{marginRight:20}}/>


                    <ConnectButton
                        web3={props.web3}
                        account={props.account}
                        setWeb3={setWeb3}
                        hovStyle={hovStyleButton}
                        normalStyle={normalStyleButton}
                    />

                </div>
            </div>
            */}
            <div className={'Menu p-3'} style={{height:'80px'}}>

                <Col className='d-flex align-items-center'>
                    <iframe src="https://giphy.com/embed/htSM5MPkwlKaSYE7yN" width="50" height="50" frameBorder="0"
                            className="giphy-embed" allowFullScreen></iframe>
                    <p
                        style={{margin:10,color:'white', fontSize:'20px', fontWeight:'bold'}}
                        onClick={() => navigate("/")}
                    >
                        EclipseNFT
                    </p>
                </Col>
                <Col>
                    <div className='d-flex align-items-center justify-content-center'>
                        <p onMouseEnter={ () => setHov1(true) }
                           onMouseLeave={ () => setHov1(false) }
                           style={(hov1)? hovStyle : normalStyle }
                           onClick={() => navigate("/artist")}
                        >
                            Creators
                        </p>

                        <p onMouseEnter={ () => setHov2(true) }
                           onMouseLeave={ () => setHov2(false) }
                           style={(hov2)? hovStyle : normalStyle }
                           onClick={() => navigate("/fan")}
                        >
                            Fans
                        </p>
                    </div>
                </Col>
                <Col>

                </Col>
            </div>
            <div className={'d-flex'} style={{zIndex:1000, position:'absolute', top:20, right:20}}>
                <SelectNetwork setNetwork={(n) => props.setNetwork(n)}/>
                <ConnectButton
                    web3={props.web3}
                    account={props.account}
                    setWeb3={setWeb3}
                    hovStyle={hovStyleButton}
                    normalStyle={normalStyleButton}
                />
            </div>

        </>

    );
}



function MobileMenu(props) {

    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [hov0, setHov0] = useState(false);
    const [hov1, setHov1] = useState(false);
    const [hov2, setHov2] = useState(false);

    const setWeb3 = (w,a) => props.setWeb3(w,a);

    return (
        <div>
            <Row className={'Menu p-3'}>
                <Col className='d-flex align-items-center'>
                    <iframe src="https://giphy.com/embed/htSM5MPkwlKaSYE7yN" width="50" height="50" frameBorder="0"
                            className="giphy-embed" allowFullScreen></iframe>
                    <p
                        style={{margin:10,color:'white', fontSize:'20px', fontWeight:'bold'}}
                        onClick={() => navigate("/")}
                    >
                        EclipseNFT
                    </p>
                </Col>
                <Col className='d-flex align-items-center justify-content-center'>

                </Col>
                <Col className='d-flex align-items-center justify-content-end'>
                    {   (!open)?
                        <FiMenu style={{fontSize:30, color:'white'}}
                                onClick={() => setOpen(!open)}
                        />
                        :
                        <FiX style={{fontSize:30, color:'white'}}
                             onClick={() => setOpen(!open)}
                        />
                    }
                </Col>
            </Row>
            {
                (open)?
                    <div className={'MobileMenu d-flex flex-column align-items-left w-100'} >
                        <div style={{width:'100%', height:2, backgroundColor:'#7900ff', }}></div>
                        <ConnectButtonHeaderMobile
                            web3={props.web3}
                            account={props.account}
                            setWeb3={setWeb3}
                            closeSlider={() => setOpen(false)}
                        />
                        <p onMouseEnter={ () => setHov1(true) }
                           onMouseLeave={ () => setHov1(false) }
                           style={(hov1)? hovStyle : normalStyle }
                           onClick={() => {
                               setOpen(false);
                               navigate("/artist");
                           }}
                        >
                            Creators
                        </p>
                        <p onMouseEnter={ () => setHov2(true) }
                           onMouseLeave={ () => setHov2(false) }
                           style={(hov2)? hovStyle : normalStyle }
                               onClick={() => {
                               setOpen(false);
                               navigate("/fan");
                           }}
                        >
                            Fans
                        </p>
                        <SelectNetwork setNetwork={(n) => props.setNetwork(n)}/>

                    </div>
                    :
                    <></>
            }
        </div>
    );
}




const hovStyle= {
    color: 'lightgrey',
    fontSize: 20,
    fontWeight: 'bold',
    margin:10,
    marginLeft:20,
    marginRight:20,
}

const normalStyle={
    color: 'white',
    fontSize: 20,
    fontWeight: 'bold',
    margin:10,
    marginLeft:20,
    marginRight:20,
}

const hovStyleButton= {
    fontSize: 16,
    fontWeight: 'bold',
    borderWidth:'2px',
    width:150,
    height:40
}

const normalStyleButton={
    fontSize: 16,
    fontWeight: 'bold',
    borderWidth:'2px',
    width:150,
    height:40
}

