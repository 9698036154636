import * as React from "react";
import 'react-jinke-music-player/assets/index.css'

import moment from 'moment';
moment().format();

export default function Track(props) {
    return (
        <div className={'d-flex w-100'}>
            <img src={props.image} style={{height: 60, width: 60}}/>
            <div style={{width: '100%', height: 60, marginLeft: 15, marginTop: 2, borderBottom: 'solid 1px'}}>
                <p style={{color: 'black', fontSize: 18, fontWeight: 'bold'}}>{props.title}</p>
                <p style={{marginTop: -15, color: 'grey', fontSize: 16, fontWeight: 'bold'}}>{props.artist}</p>
            </div>
        </div>
    );
}