import '../App.css';
import {useState} from "react";
import * as React from "react";
import web3Modal from "../web3Modal";
import Web3 from "web3";

function ConnectButtonHeaderMobile(props) {

    const [hov1, setHov1] = useState(false);
    const [disconnected, setDisconnected] = useState(false);

    const connectWallet = async () =>{
        const provider = await web3Modal.connect();
        const web3 = new Web3(provider);
        let activeAccount = (await web3.eth.getAccounts())[0];
        props.setWeb3(web3,activeAccount);
        setDisconnected(false);
    }

    const disconnectWallet = async () =>{
        setDisconnected(true);
    }

    return (
        <>
            {(props.account && !disconnected)?
                <p
                    onMouseEnter={ () => setHov1(true) }
                    onMouseLeave={ () => setHov1(false) }
                    style={(hov1)? hovStyle : normalStyle }
                    onClick={() => {
                        disconnectWallet();
                        props.closeSlider()
                    }}
                >
                    {
                        `${props.account.substring(0,5)}...${props.account.substring(props.account.length - 5,props.account.length)}`
                    }
                </p>
                :
                <p
                    onMouseEnter={ () => setHov1(true) }
                    onMouseLeave={ () => setHov1(false) }
                    style={(hov1)? hovStyle : normalStyle }
                    onClick={() => {
                        connectWallet();
                        props.closeSlider()
                    }}
                >
                    Connect
                </p>
            }
        </>
    );

}

const hovStyle= {
    color: 'lightgrey',
    fontSize: 20,
    fontWeight: 'bold',
    margin:10,
    marginLeft:20,
    marginRight:20,
}

const normalStyle={
    color: 'white',
    fontSize: 20,
    fontWeight: 'bold',
    margin:10,
    marginLeft:20,
    marginRight:20,
}

export default ConnectButtonHeaderMobile;