import {useEffect, useState} from "react";
import {Button, Card, Col, Modal, Row} from "react-bootstrap";
import AudioPlayer from "react-h5-audio-player";
import {Player} from "video-react";

import * as React from "react";
import {css} from "@emotion/react";

export default function PrivateNFT(props){

    return (
        <div>
            <Card className={'d-flex flex-column '}
                  style={{width:300, backgroundColor:"mistyrose", borderRadius:10}}>
                {
                    (props.audio.substring(0, props.audio.indexOf('?')).slice(-3) === 'mp4' ||
                        props.audio.substring(0, props.audio.indexOf('?')).slice(-9) === 'quicktime'
                    )?
                        <div className={'d-flex align-items-center'} style={{height:300, width:300, borderRadius:'2% 2% 0% 0%', backgroundColor:'black'}}>
                            <Player
                                playsInline
                                poster={props.image}
                                src={props.audio}
                                style={{height:375, width:375, borderRadius:'2% 2% 0% 0%'}}
                            />
                        </div>
                        :
                        <div style={{height:300, width:300, borderRadius:'2% 2% 0% 0%', backgroundColor:'black'}}>
                            <img src={props.image} style={{height:300, width:300, borderRadius:'2% 2% 0% 0%'}}/>
                        </div>
                }
                <div style={{padding:10}}>
                    <>

                        <div className={'d-flex justify-content-between'}>
                            <div>
                                <p style={{ color:'black', fontSize:18, fontWeight:'bold', marginTop:5}}>
                                    {props.title}
                                </p>
                                <p style={{ marginTop:-15, color:'grey', fontSize:16, fontWeight:'bold'}}>
                                    {props.artist}
                                </p>
                            </div>
                            <div style={{textAlign:'right', marginTop:5, marginRight:10}}>
                                <p style={{ color:'black', fontSize:18, fontWeight:'bold'}}> Plays Remaining </p>
                                <p style={{ marginTop:-15, color:'grey', fontSize:16, fontWeight:'bold'}}>{props.totalPlays + '/' + props.totalPlays}</p>
                            </div>
                        </div>

                        {
                            (props.audio.substring(0, props.audio.indexOf('?')).slice(-3) === 'mp4')?
                                <></>
                                :
                                <AudioPlayer
                                    src={props.audio}
                                    onPlay={e => console.log("onPlay")}
                                />
                        }
                    </>
                </div>
            </Card>
        </div>


    );
}


const playbutton ={
    color:'black',
    fontWeight:'bold',
}

const playbutton_hov ={
    color:'white',
    fontWeight:'bold',
}
const button ={
    height:50,
    width:250,
    backgroundColor:'#7900ff',
    borderColor:'#7900ff',
    color:'white',
    fontWeight:'bold',
    fontSize:25
}

const button_hov ={
    height:50,
    width:250,
    backgroundColor:'rgb(35,10,151)',
    borderColor:'rgb(35,10,151)',
    color:'white',
    fontWeight:'bold',
    fontSize:25
}

const titleText = {
    color:'#7900ff',
    fontWeight:'bold',
    fontSize:20,
    marginLeft:15,
    marginTop:0
}


const override = css`
  position: fixed;
  top:40%;
  left:45%;
  display: block;
  margin: 0 auto;
  border-color: #7900ff;
  z-index: 1000;
`;