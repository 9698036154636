import {useEffect, useState} from "react";
import {Button, Card, Col, Modal, Row} from "react-bootstrap";

import * as React from "react";
import {css} from "@emotion/react";
import {BsPlayCircle} from "react-icons/bs";

export default function PublicNFT(props){
    const [hov, setHov] = useState(false);
    const [hov1, setHov1] = useState(false);
    const [show, setShow] = useState(false);
    const [play, setPlay] = useState(false);
    const [audio, setAudio] = useState('');

    return (
        <div>
            <Card className={'d-flex flex-column '}
                  style={{width:300, backgroundColor:"mistyrose", borderRadius:10}}
            >
                <div style={{height:300, width:300, borderRadius:'2% 2% 0% 0%', backgroundColor:'black'}}>
                    <img src={props.image} style={{height:300, width:300, borderRadius:'2% 2% 0% 0%'}}/>
                </div>
                <div style={{padding:10}}>
                    <div className={'d-flex justify-content-between'}>
                        <div>
                            <p style={{ color:'black', fontSize:18, fontWeight:'bold', marginTop:5}}>
                                {props.title}
                            </p>
                            <p style={{ marginTop:-15, color:'grey', fontSize:16, fontWeight:'bold'}}>
                                {props.artist}
                            </p>
                        </div>
                        <div className={'d-flex'}>
                            <div style={{textAlign:'right', marginTop:5, marginRight:10}}>
                                <p style={{ color:'black', fontSize:18, fontWeight:'bold'}}> Plays Remaining </p>
                                <p style={{ marginTop:-15, color:'grey', fontSize:16, fontWeight:'bold'}}>{props.totalPlays + '/' + props.totalPlays}</p>
                            </div>
                            <div style={{marginTop:-10,fontSize:50}}>
                                <BsPlayCircle />
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    );
}


const playbutton ={
    color:'black',
    fontWeight:'bold',
}

const playbutton_hov ={
    color:'white',
    fontWeight:'bold',
}
const button ={
    height:50,
    width:250,
    backgroundColor:'#7900ff',
    borderColor:'#7900ff',
    color:'white',
    fontWeight:'bold',
    fontSize:25
}

const button_hov ={
    height:50,
    width:250,
    backgroundColor:'rgb(35,10,151)',
    borderColor:'rgb(35,10,151)',
    color:'white',
    fontWeight:'bold',
    fontSize:25
}

const titleText = {
    color:'#7900ff',
    fontWeight:'bold',
    fontSize:20,
    marginLeft:15,
    marginTop:0
}


const override = css`
  position: fixed;
  top:40%;
  left:45%;
  display: block;
  margin: 0 auto;
  border-color: #7900ff;
  z-index: 1000;
`;