import { useState } from 'react';
import { Row,Col } from 'react-bootstrap';
import {BsPatchQuestion, BsMedium, BsTwitter, BsDiscord} from 'react-icons/bs'

function Footer() {

  const [hov1, setHov1] = useState(false);
  const [hov2, setHov2] = useState(false);
  const [hov3, setHov3] = useState(false);

  return (
      <div className="Footer" style={{marginTop:-110}}>
          <div style={{display:'flex', justifyContent:'end',  margin: 'auto'}}>
            <BsTwitter onClick={()=>{ window.open('https://twitter.com/_eclipseNFT', '_blank')}} style={hov1? menuStyleHov : menuStyle} onMouseEnter={() => setHov1(true)} onMouseLeave={() => setHov1(false)}/>
            <BsMedium  onClick={()=>{ window.open('https://eclipsenft.medium.com/', '_blank')}} style={hov2? menuStyleHov : menuStyle} onMouseEnter={() => setHov2(true)} onMouseLeave={() => setHov2(false)}/>
            <BsDiscord onClick={()=>{ window.open('https://discord.gg/5SwWQr5M74', '_blank')}} style={hov3? menuStyleHov : menuStyle} onMouseEnter={() => setHov3(true)} onMouseLeave={() => setHov3(false)}/>
          </div>
      </div>
  );
}

const menuStyle = {color:'#7900ff', fontSize:30, margin:40, fontWeight:'bold',zIndex:20 }
const menuStyleHov = {color:'rgb(35,10,151)', fontSize:30, margin:40, fontWeight:'bold',zIndex:20 }


export default Footer;