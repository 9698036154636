import {useNavigate} from "react-router-dom";
import {useState} from "react";
import audioBackground from "../../images/audiobackground.png";
import videoBackground from "../../images/earth-rotating-2.mp4";
import {Button, Col, Row} from "react-bootstrap";
import * as React from "react";
import Footer from "../../components/Footer";

export default function Home(props) {
    const navigate = useNavigate();

    const [hov1, setHov1] = useState(false);
    const [hov2, setHov2] = useState(false);

    return (
        <div className={'Home'}>
          {/*<img className='BackgroundImage' src={audioBackground} style={{width:'90%', height:400}}></img>*/}

          <div id="intro" class="bg-image vh-100">
            <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
              <source class="h-100" src={videoBackground} type="video/mp4" />
            </video>
            <div class="mask">
              <div class="container d-flex align-items-center justify-content-center text-center h-100">
                
                <Row>
                  <div class={'col col-md-10 col-lg-8 mx-auto'}>
                    <div class="text-white">
                      <h2 class="mb-3">Create private, customizable NFTs from your Music, TikTok, and other social content</h2>
                      <h5 class="mb-4">Regain direct monetary control of your content</h5>
                      <Button variant="primary" size="lg" style={{width:150}} onClick={() => navigate("/mint")}>
                        Mint an NFT
                      </Button>&nbsp;&nbsp;&nbsp;
                      <Button variant="primary" size="lg"  style={{width:150}} onClick={() => navigate("/fan")}>
                        View NFTs
                      </Button>
                    </div>
                  </div>
                </Row>
                
              </div>
            </div>
          </div>
          <Footer/>
        </div>
    );
}

const artistText = {
    color:'#7900ff',
    fontWeight:'bold',
    fontSize:50
}

const fanText = {
    color:'#7900ff',
    fontWeight:'bold',
    fontSize:50
}

const mintButton ={
    height:60,
    width:280,
    background: 'rgb(2,0,36)',
    background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 59%, #7900ff 100%)',
    borderColor:'#7900ff',
    color:'white',
    fontWeight:'bold',
    fontSize:30
}

const listenButton ={
    height:60,
    width:280,
    backgroundColor:'#7900ff',
    borderColor:'#7900ff',
    color:'white',
    fontWeight:'bold',
    fontSize:30
}

const mintButton_hov ={
    height:60,
    width:280,
    backgroundColor:'#7900ff',
    borderColor:'#7900ff',
    color:'white',
    fontWeight:'bold',
    fontSize:30
}

const listenButton_hov ={
    height:60,
    width:280,
    backgroundColor:'rgb(35,10,151)',
    borderColor:'rgb(35,10,151)',
    color:'white',
    fontWeight:'bold',
    fontSize:30
}
