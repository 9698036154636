import * as React from "react";
import {useState, useEffect} from "react";
import 'react-jinke-music-player/assets/index.css'
import {Button, Form, Table, Card, Row, Col,Modal} from "react-bootstrap";
import Track from "./Track";
import moment from 'moment';
import {useNavigate} from "react-router-dom";
import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../../firebase";
import AudioPlayer from "react-h5-audio-player";
import {chainId} from "../../contractArtifacts/contractDetails";
import {Player} from "video-react";
import ReactAudioPlayer from "react-audio-player";
import ArtistTrackCard from "./ArtistTrackCard";

moment().format();


export default function Artist(props) {
    const navigate = useNavigate();

    const [hov1, setHov1] = useState(false);
    const [hov2, setHov2] = useState(false);
    const [track, setTrack] = useState(0);

    const [songs, setSongs] = useState([]);


    useEffect(()=>{
        const getMintedNFTs = async() => {

            let mintedNFTs=[];

            let details = {}
            const q = query(collection(db, "nft_secret"), where("artistAddress", "==", props.account));
            const querySnapshot = await getDocs(q);

            querySnapshot.forEach((doc) => {
                details = {... doc.data() }

                let song ={
                    "image": doc.data().imgSrc,
                    "title": doc.data().track,
                    "artist": doc.data().artist,
                    "hash": doc.data().metadataHash,
                    "playsRemaining":doc.data().totalPlays,
                    "publicReleaseDate": doc.data().publicRelease,
                    "audio": doc.data().audio
                }
                mintedNFTs.push(song);

            });

            setSongs([...mintedNFTs]);
        }

        if(props.account){
            getMintedNFTs();
        }
    }, [props.account])

    return (
        <>
            { (!props.account)?

                <div style={{width:'100%'}}>
                    <p style={{width:'80%', maxWidth:400, margin:'auto',marginTop:80, color:'black', fontSize:18, fontWeight:'bold'}}>
                        Connect your wallet to view your Mints
                    </p>
                </div>

                :
                <></>
            }
            { (props.account && (songs.length > 0))?
                <>
                    <div style={{margin:40}}>
                        <p style={artistText}>Previous Mints</p>
                    </div>
                    <Row style={{margin:10, marginTop:0}}>
                        <Col style={{height:350, }}>
                            <div style={{height:350, width:'100%', overflowY:'scroll'}}>
                                <Table style={{height:350, width:'100%', overflowY:'scroll'}} hover>
                                    <tbody style={{height:350, width:'100%'}}>
                                    {
                                        Array.from({ length:songs.length }).map((_, index) => (
                                            <tr onClick={() => setTrack(index)}>
                                                <Track
                                                    key={index}
                                                    image={songs[index].image}
                                                    title={songs[index].title}
                                                    artist={songs[index].artist}
                                                    playsRemaining={songs[index].playsRemaining}
                                                    publicReleaseDate={songs[index].publicReleaseDate}
                                                />
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                        <Col className={'d-flex justify-content-center'} style={{padding:20}}>
                            <ArtistTrackCard
                                web3={props.web3}
                                account={props.account}
                                image={songs[track].image}
                                audio={songs[track].audio}
                                title={songs[track].title}
                                artist={songs[track].artist}
                            />
                        </Col>
                        <Col className={'d-flex justify-content-center'}>
                            <div style={{width:'95%'}}>
                                <p style={artistText}>Actions</p>
                                <Button
                                    onMouseEnter={ () => setHov1(true) }
                                    onMouseLeave={ () => setHov1(false) }
                                    style={(hov1)? listenButton_hov : listenButton }
                                    onClick={() => navigate("/mint")}
                                >
                                    Mint
                                </Button>
                                <div style={{marginTop:20}}>
                                    <Button
                                        onMouseEnter={ () => setHov2(true) }
                                        onMouseLeave={ () => setHov2(false) }
                                        style={(hov2)? listenButton_hov : listenButton }
                                        onClick={()=>{
                                            let url =''
                                            if(props.networkId == 1 || 137){
                                                url = 'https://opensea.io/account';
                                            }
                                            else if (props.networkId == 4){
                                                url = 'https://testnets.opensea.io/account'
                                            }
                                            else{
                                                console.log('network not supported')
                                            }
                                            window.open(url, '_blank');
                                        }}
                                    >
                                        Sell
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </>
                :
                <></>
            }
            { (props.account && (songs.length == 0))?
                <>
                    <Row style={{margin:40, height:333}}>
                        <Col sm={1}></Col>
                        <Col style={{padding:20}}>
                            <p style={{marginTop:30, color:'black', fontSize:18, fontWeight:'bold'}}>
                                No mints found.
                            </p>
                        </Col>
                        <Col className={'d-flex justify-content-center'}>
                            <div style={{width:'100%'}}>
                                <p style={artistText}>Actions</p>
                                <Button
                                    onMouseEnter={ () => setHov1(true) }
                                    onMouseLeave={ () => setHov1(false) }
                                    style={(hov1)? listenButton_hov : listenButton }
                                    onClick={() => navigate("/mint")}
                                >
                                    Mint
                                </Button>
                                <div style={{marginTop:20}}>
                                    <Button
                                        onMouseEnter={ () => setHov2(true) }
                                        onMouseLeave={ () => setHov2(false) }
                                        style={(hov2)? listenButton_hov : listenButton }
                                        onClick={()=>{
                                            const url = 'https://opensea.io/';
                                            window.open(url, '_blank');
                                        }}
                                    >
                                        Sell
                                    </Button>
                                </div>
                            </div>
                        </Col>
                        <Col sm={1}></Col>
                    </Row>

                </>
                :
                <></>
            }
        </>
    );
}

const artistText = {
    color:'#7900ff',
    fontWeight:'bold',
    fontSize:25
}

const listenButton ={
    height:50,
    width:'100%',
    minWidth:200,
    maxWidth:350,
    backgroundColor:'#7900ff',
    borderColor:'#7900ff',
    color:'white',
    fontWeight:'bold',
    fontSize:25
}

const listenButton_hov ={
    height:50,
    width:'100%',
    minWidth:200,
    maxWidth:350,
    backgroundColor:'rgb(35,10,151)',
    borderColor:'rgb(35,10,151)',
    color:'white',
    fontWeight:'bold',
    fontSize:25
}
