import {useState} from "react";
import * as React from "react";
import {DropdownButton, Dropdown} from "react-bootstrap";
import {GoTriangleDown} from "react-icons/all";

import '../App.css';

import ethereum from '../images/ethereum.png';
import polygon from '../images/polygon.png';
import secretNetwork from '../images/secreNetwork.png';
import testnet from '../images/testnet.png';

let networkImages = [ethereum,polygon, testnet, secretNetwork];
let networkNames = ['ethereum','polygon', 'testnet', 'secret network'];


export default function SelectNetwork(props) {

    const [toggle, setToggle] = useState(false);
    const [hov, setHov] = useState(false);
    const [option, setOption] = useState(1);

    return(

        <div className={'d-flex flex-column'} style={{width:165, marginRight:10}}>
            {/*Currently Selected*/}
            <div
                className={'d-flex w-100 justify-content-start align-items-center'}
                style={{
                    height:40,
                    backgroundColor: '#7900ff',
                    color:'white',
                    fontWeight:'bold',
                    borderStyle:'solid',
                    borderColor:'white',
                    borderWidth: '2px',
                    borderRadius: (!toggle)? '5px 5px 5px 5px' : '5px 5px 0px 0px',
                    padding:10,
                    transform: 'translateZ(0)'
                }}
                onClick={() => setToggle(!toggle)}
            >

                <div className={'d-flex justify-content-between align-items-center'}>
                    <div className={'d-flex mt-3'}>
                        <img style={{height:25, marginRight:6}} src={networkImages[option]}/>
                        <p>{networkNames[option]}</p>
                    </div>
                </div>
                <GoTriangleDown style={{position:"absolute", right:10}}/>
            </div>
            {
                (toggle)?
                    <div className={'w-100'}
                         style={{
                             backgroundColor: 'whitesmoke',
                             color:'black',
                             fontWeight:'bold',
                             borderStyle:'solid',
                             borderColor:'white',
                             borderWidth: '2px',
                             borderTopWidth:0,
                             borderRadius:'0% 0% 5px 5px',
                             transform: 'translateZ(0)'
                         }}

                    >
                        {/*
                        <div className={'networkItem d-flex p-2 pb-0'}
                             onClick={() => {
                                 setOption(0);
                                 setToggle(!toggle);
                                 props.setNetwork(1);
                             }
                             }
                        >
                            <img style={{height:20}} src={networkImages[0]}/>
                            <p style={{marginTop:-3, marginLeft:5}}>{networkNames[0]}</p>
                        </div>
                        */}
                        <div className={'networkItem d-flex p-2 pb-0'}
                             onClick={() => {
                                 setOption(1);
                                 setToggle(!toggle);
                                 props.setNetwork(137);
                             }
                             }
                        >
                            <img style={{height:20}} src={networkImages[1]}/>
                            <p style={{marginTop:-4, marginLeft:5}}>{networkNames[1]}</p>
                        </div>
                        <div className={'networkItem d-flex p-2 pb-0'}
                             onClick={() => {
                                 setOption(2);
                                 setToggle(!toggle);
                                 props.setNetwork(4);
                             }
                             }
                        >
                            <img style={{height:20}} src={networkImages[2]}/>
                            <p style={{marginTop:-5, marginLeft:5}}>{networkNames[2]}</p>
                        </div>
                        <div className={'networkItem p-2 pb-0'}
                             onMouseEnter={() => setHov(true)}
                             onMouseLeave={() => setHov(false)}
                             onClick={() => {
                                 //setOption(3);
                                 //setToggle(!toggle);
                                 //props.setNetwork(9999);
                             }
                             }
                        >
                            <div className={'d-flex'}>
                                <img style={{height:20}} src={networkImages[3]}/>
                                <p style={{marginTop:-4, marginLeft:5}}>{networkNames[3]}</p>
                            </div>
                            <div style={(hov)? hovStyle : basicStyle}>
                                (Coming Soon!)
                            </div>
                        </div>
                    </div>
                    :
                    <></>
            }
            </div>
    );
}

const hovStyle = {marginLeft:25,marginTop:-20, color:'white', fontWeight:'bold', fontSize:12, fontStyle:'italic'}

const basicStyle = {marginLeft:25,marginTop:-20,color:'#7900ff', fontWeight:'bold', fontSize:12, fontStyle:'italic'}
