import '../App.css';
import {useState} from "react";
import Button from "react-bootstrap/Button";
import * as React from "react";
import web3Modal from "../web3Modal";
import Web3 from "web3";



function ConnectButton(props) {

  const [hov1, setHov1] = useState(false);
  const [disconnected, setDisconnected] = useState(false);

  const connectWallet = async () =>{
    const provider = await web3Modal.connect();
    const web3 = new Web3(provider);
    let activeAccount = (await web3.eth.getAccounts())[0];
    props.setWeb3(web3,activeAccount);
    setDisconnected(false);
  }

  const disconnectWallet = async () =>{
    setDisconnected(true);
  }

  return (
      <>
        {(props.account && !disconnected)?
          <Button
              variant="outline-light"
              onMouseEnter={ () => setHov1(true) }
              onMouseLeave={ () => setHov1(false) }
              style={(hov1)? props.hovStyle : props.normalStyle }
              onClick={disconnectWallet}
          >
            {
              `${props.account.substring(0,5)}...${props.account.substring(props.account.length - 5,props.account.length)}`
            }
          </Button>
            :
          <Button
            variant="outline-light"
            onMouseEnter={ () => setHov1(true) }
            onMouseLeave={ () => setHov1(false) }
            style={(hov1)? props.hovStyle : props.normalStyle }
            onClick={connectWallet}
          >
            Connect
          </Button>
        }
      </>
  );
}



export default ConnectButton;