import {useEffect, useState} from "react";
import {ABI, contractAddress} from "../../contractArtifacts/contractDetails";
import {collection, getDocs, query, where} from "firebase/firestore";
import {db, functions} from "../../firebase";
import {Button, Card, Col, Modal, Row} from "react-bootstrap";
import {BsPlayCircle} from "react-icons/bs";
import AudioPlayer from "react-h5-audio-player";
import {Player} from "video-react";

import {httpsCallable} from "firebase/functions";
import GridLoader from "react-spinners/GridLoader";
import * as React from "react";
import {css} from "@emotion/react";


const createMetadata = httpsCallable(functions, 'createMetadata');


export default function TrackCard(props){
    const [hov, setHov] = useState(false);
    const [hov1, setHov1] = useState(false);
    const [show, setShow] = useState(false);
    const [play, setPlay] = useState(false);
    const [audio, setAudio] = useState('');

    const [playCount, setPlayCount] = useState(0);

    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState('#7900ff');

    useEffect(()=>{
        const getPlayCount = async() => {
            let eclipseContract = new props.web3.eth.Contract(ABI,contractAddress);
            let playsRemaining = await eclipseContract.methods.playCount(props.tokenId).call({from: props.account})

            setPlayCount(Number(playsRemaining));
        }

        if(props.web3){
            getPlayCount();
        }
    },[])

    const handlePlay = async()=>{
        setLoading(true);
        //1) Get new metadata from cloud function
        let updatedMetadata = (await createMetadata({
            imgSrc: props.image,
            name: props.track,
            artist: props.artist,
            artistAddress: props.artistAddress,
            track: props.track,
            album: props.album,
            totalPlays: props.totalPlays,
            playsRemaining: playCount - 1,
            publicReleaseDate: props.publicReleaseDate
        })).data;

        console.log(updatedMetadata)


        //2) submit blockchain transaction
        let eclipseContract = new props.web3.eth.Contract(ABI,contractAddress);

        try{
            let res = eclipseContract.methods.play(props.tokenId, updatedMetadata.metadataURI).send({from: props.account}, function(error,result){
                if(error){
                    setLoading(false)
                }
            })

            res.on('receipt', async function(receipt) {



                    const q = query(collection(db, "nft_secret"), where("metadataHash", "==", props.metadataHash));

                    //2) get secret audio
                    const querySnapshot = await getDocs(q);
                    querySnapshot.forEach((doc) => {
                        // doc.data() is never undefined for query doc snapshots
                        console.log(doc.id, " => ", doc.data());
                        setAudio(doc.data().audio)
                    });

                    //3) Show audio player
                    setLoading(false);
                    setPlay(true);
                    setPlayCount(old => old - 1)

                })


        }
        catch(e){
            console.log(e)
            setLoading(false)
        }
    }

    return (
        <div>
            <Card className={'d-flex flex-column '}
                  style={{height:460, width:375, backgroundColor:"mistyrose", borderRadius:10}}>
                {
                    (   audio.substring(0, audio.indexOf('?')).slice(-3) === 'mp4' ||
                        audio.substring(0, audio.indexOf('?')).slice(-9) === 'quicktime'
                    )?
                        <div
                            className={'d-flex align-items-center'}
                            style={{ height:375, width:375, borderRadius:'2% 2% 0% 0%', backgroundColor:'black'}}
                        >
                            <Player
                                playsInline
                                poster={props.image}
                                src={audio}
                                style={{ height:375, width:375, marginTop:10, borderRadius:'2% 2% 0% 0%'}}
                                onEnded={() => {
                                    setPlay(false);
                                    setAudio('');
                                }}
                            />
                        </div>
                        :
                        <div style={{ height:375, width:375, borderRadius:'2% 2% 0% 0%', backgroundColor:'black'}}>
                            <img src={props.image} style={{height:375, width:375, borderRadius:'2% 2% 0% 0%'}}/>
                        </div>

                }
                <div style={{padding:10}}>
                    {
                        play?
                            <>
                                <div className={'d-flex justify-content-between'}>
                                    <div>
                                        <p style={{ color:'black', fontSize:18, fontWeight:'bold', marginTop:5}}>
                                            {props.title}
                                        </p>
                                        <p style={{ marginTop:-15, color:'grey', fontSize:16, fontWeight:'bold'}}>
                                            {props.artist}
                                        </p>
                                    </div>
                                    <div style={{textAlign:'right', marginTop:5, marginRight:10}}>
                                        <p style={{ color:'black', fontSize:18, fontWeight:'bold'}}> Plays Remaining </p>
                                        <p style={{ marginTop:-15, color:'grey', fontSize:16, fontWeight:'bold'}}>{playCount + '/' + props.totalPlays}</p>
                                    </div>
                                </div>
                                {
                                    (audio.substring(0, audio.indexOf('?')).slice(-3) === 'mp4' || audio.substring(0, props.audio.indexOf('?')).slice(-9) === 'quicktime'

                                    )?
                                        <></>
                                        :
                                        <AudioPlayer
                                            src={audio}
                                            onPlay={e => console.log("onPlay")}
                                            onEnded={() => {
                                                setPlay(false);
                                                setAudio('');
                                            }}
                                        />
                                }
                            </>
                            :
                            <div className={'d-flex justify-content-between'}>
                                <div>
                                    <p style={{ color:'black', fontSize:18, fontWeight:'bold', marginTop:5}}>
                                        {props.title}
                                    </p>
                                    <p style={{ marginTop:-15, color:'grey', fontSize:16, fontWeight:'bold'}}>
                                        {props.artist}
                                    </p>
                                </div>
                                <div className={'d-flex'}>
                                    <div style={{textAlign:'right', marginTop:5, marginRight:10}}>
                                        <p style={{ color:'black', fontSize:18, fontWeight:'bold'}}> Plays Remaining </p>
                                        <p style={{ marginTop:-15, color:'grey', fontSize:16, fontWeight:'bold'}}>{playCount + '/' + props.totalPlays}</p>
                                    </div>
                                    <div style={{marginTop:-10,fontSize:50}}>
                                        <BsPlayCircle onMouseEnter={ () => setHov(true) }
                                                      onMouseLeave={ () => setHov(false) }
                                                      style={(hov)? playbutton_hov : playbutton }
                                                      onClick={handlePlay}
                                        />
                                    </div>
                                </div>
                            </div>
                    }
                </div>
                <GridLoader color={color} loading={loading} css={override} size={35} />
            </Card>
            {
                /*
<Modal size={'lg'} show={show} fullscreen={'md-down'} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                </Modal.Header>

                <Modal.Body>
                    <Row>
                        <Col>
                            <img src={props.image} style={{ width:'100%'}}/>
                            <p style={{marginTop:20, color:'black', fontSize:18, fontWeight:'bold'}}>
                                {props.title}
                            </p>
                            <p style={{ marginTop:-15, color:'grey', fontSize:16, fontWeight:'bold'}}>
                                {props.artist}
                            </p>
                        </Col>
                        <Col>
                            <p style={titleText}>Plays Remaining: {playCount + '/' + props.totalPlays}</p>
                            <div style={{backgroundColor:"mistyrose", padding:20,}}>
                                <p>
                                    This exclusive track can only be played {playCount} more times before it is burned.
                                </p>
                                <p>
                                    Public release: {(props.publicReleaseDate !== '') ? props.publicReleaseDate : 'No date set for release'}
                                </p>
                            </div>
                            <div className={'d-flex flex-column align-items-center mt-5'}>
                                {
                                    play?
                                        <>
                                            {
                                                (audio.substring(0, audio.indexOf('?')).slice(-3) === 'mp4')?
                                                    <Player
                                                        playsInline
                                                        poster={props.image}
                                                        src={audio}
                                                        onEnded={() => {
                                                            setPlay(false);
                                                            setAudio('');
                                                        }}
                                                    />
                                                    :
                                                    <AudioPlayer
                                                        src={audio}
                                                        onPlay={e => console.log("onPlay")}
                                                        onEnded={() => {
                                                            setPlay(false);
                                                            setAudio('');
                                                        }}
                                                    />
                                            }
                                        </>
                                        :
                                        <Button
                                            onMouseEnter={ () => setHov1(true) }
                                            onMouseLeave={ () => setHov1(false) }
                                            style={(hov1)? button_hov : button }
                                            onClick={handlePlay}

                                        >
                                            Play Track
                                        </Button>
                                }
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <GridLoader color={color} loading={loading} css={override} size={50} />
            </Modal>

                 */
            }
        </div>


    );
}


const playbutton ={
    color:'black',
    fontWeight:'bold',
}

const playbutton_hov ={
    color:'white',
    fontWeight:'bold',
}
const button ={
    height:50,
    width:250,
    backgroundColor:'#7900ff',
    borderColor:'#7900ff',
    color:'white',
    fontWeight:'bold',
    fontSize:25
}

const button_hov ={
    height:50,
    width:250,
    backgroundColor:'rgb(35,10,151)',
    borderColor:'rgb(35,10,151)',
    color:'white',
    fontWeight:'bold',
    fontSize:25
}

const titleText = {
    color:'#7900ff',
    fontWeight:'bold',
    fontSize:20,
    marginLeft:15,
    marginTop:0
}


const override = css`
  position: fixed;
  top:40%;
  left:45%;
  display: block;
  margin: 0 auto;
  border-color: #7900ff;
  z-index: 1000;
`;