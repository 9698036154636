import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import Web3Modal from "web3modal";
import Torus from "@toruslabs/torus-embed";
import Fortmatic from "fortmatic";

const providerOptions = {
    walletconnect: {
        package: WalletConnectProvider, // required
        options: {
            infuraId: "3b1014869e8f45bfaf89bce0f78a0e7c" // required
        }
    },
    fortmatic: {
        package: Fortmatic, // required
        options: {
            key: "pk_live_EF64FB98BE56B051" // required
        }
    },
    torus: {
        package: Torus, // required
        options: {
            networkParams: {
                host: "https://localhost:8545", // optional
                chainId: 1337, // optional
                networkId: 1337 // optional
            },
            config: {
                buildEnv: "development" // optional
            }
        }
    }
};

const web3Modal = new Web3Modal({
    network: "mainnet", // optional
    cacheProvider: true, // optional
    providerOptions // required
});

export default web3Modal;