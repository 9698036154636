import * as React from "react";
import {useState, useEffect, useRef} from "react";
import {Routes, Route, Outlet, Link} from "react-router-dom";
import {chainId,chainName} from "./contractArtifacts/contractDetails";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from './routes/Home/Home'
import Artist from "./routes/Artist/Artist";
import Mint from "./routes/Mint/Mint";
import Fan from "./routes/Fan/Fan"
import {Alert} from "react-bootstrap";
import {chainData} from "./contractArtifacts/contractDetails";


export default function App() {
    const [web3, setWeb3] = useState();
    const [account, setAccount] = useState();
    const [tsAndCs, setTsAndCs] = useState(false);

    const [firstClick, setFirstClick] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [variant, setVariant] = useState("danger");

    const network = useRef(137);


    useEffect(() => {
        console.log("outside: ", network.current);

        if(web3 && firstClick){
            setInterval(async () => {

                const selectedNetworkId = network.current; //network value is not changing inside.  Custom hook?

                console.log("inside: ", network.current);
                const walletNetworkId = await web3.eth.net.getId();

                const selectedNetworkName = chainData.filter(item => (item.chainId === selectedNetworkId))

                if(selectedNetworkId !== walletNetworkId){
                    setAlertText(`Connect Wallet to ${selectedNetworkName[0].name}`);
                    setShowAlert(true);
                    return
                }

                let newAccount = (await web3.eth.getAccounts())[0];

                if (newAccount !== account) {
                    setAccount(newAccount);
                }

            }, 1000);
            setFirstClick(false);
        }
    }, [web3,network])

    const handleSetWeb3 = (w,a) => {
        setWeb3(w);
        setAccount(a);
    }

    return (
        <div>
            <Routes>
                <Route path="/" element={<Layout web3={web3} account={account} setWeb3={handleSetWeb3} setNetwork={(n) => network.current = n}/>}>
                    <Route index element={<Home />} />
                    <Route path="artist" element={<Artist networkId={network.current}  web3={web3} account={account} />} />
                    <Route path="mint" element={<Mint networkId={network.current} web3={web3} account={account} setWeb3={handleSetWeb3} tsAndCs={tsAndCs} setTsAndCs={() => setTsAndCs(true)}/>} />
                    <Route path="fan" element={<Fan  networkId={network.current} web3={web3} account={account}/>} />
                    <Route path="*" element={<NoMatch />} />
                </Route>
            </Routes>
            {
                (showAlert)?
                    <Alert
                        variant={variant}
                        onClose={() => setShowAlert(false)}
                        dismissible
                        style={{position:'fixed',top:20, right:20, zIndex:40000}}
                    >
                        <p>
                            {alertText}
                        </p>
                    </Alert>
                    :
                    <></>
            }
        </div>
    );
}

function Layout(props) {

    const handleSetWeb3 = (w,a) => props.setWeb3(w,a);

    return (
        <div>
            <Header
                web3={props.web3}
                account={props.account}
                setWeb3={handleSetWeb3}
                setNetwork={(n) => props.setNetwork(n) }
            />
            <Outlet />
            {/* Killing this for now --Slocombe
            <Footer />
            */}
        </div>
    );
}


function NoMatch() {
    return (
        <div>
            <h2>Nothing to see here!</h2>
            <p>
                <Link to="/">Go to the home page</Link>
            </p>
        </div>
    );
}
