import * as React from "react";
import {useState, useEffect} from "react";
import ReactJkMusicPlayer from 'react-jinke-music-player'
import 'react-jinke-music-player/assets/index.css'
import {Button, Form, Table, Card, Row, Col,Modal} from "react-bootstrap";
import {BsPlayCircle} from "react-icons/bs";

import ReactAudioPlayer from 'react-audio-player';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import {query, where, collection, getDocs } from "firebase/firestore";
import { storage, functions, db } from "../../firebase";

import moment from 'moment';
import {ABI, chainId, contractAddress} from "../../contractArtifacts/contractDetails";
import TrackCard from "./TrackCard";
moment().format();


export default function Fan(props) {

    const [songs, setSongs] = useState([]);
    const [hov1, setHov1] = useState(false);
    const [hov2, setHov2] = useState(false);
    const [hov3, setHov3] = useState(false);
    const [numberClicked, setNumberClicked] = useState(0);


    useEffect(()=>{
        const getOwnedNFTs = async() => {
            let eclipseContract = new props.web3.eth.Contract(ABI,contractAddress);

            //1) Get number of NFTs owned
            let res = await eclipseContract.methods.balanceOf(props.account).call({from: props.account})

            console.log("number of NFTs owned: ", res);

            //2) Get NFT array
            let ownedNFTs=[];
            for(let i=0; i< Number(res); i++){


                let tokenId = await eclipseContract.methods.tokenOfOwnerByIndex(props.account, i).call({from: props.account})
                let initialURI = await eclipseContract.methods.initialURIs(tokenId).call({from: props.account})

                const q = query(collection(db, "nft"), where("metadataHash", "==", initialURI));
                const querySnapshot = await getDocs(q);

                querySnapshot.forEach((doc) => {
                    let song ={
                        "image": doc.data().imgSrc,
                        "title": doc.data().track,
                        "artist": doc.data().artist,
                        "metadataHash": doc.data().metadataHash,
                        "totalPlays":doc.data().totalPlays,
                        "publicReleaseDate": doc.data().publicReleaseDate,
                        "tokenId": Number(tokenId)
                    }
                    ownedNFTs.push(song);
                });
            }
            console.log(ownedNFTs);
            setSongs(ownedNFTs);
        }

        if(props.web3){
            getOwnedNFTs();
        }

    },[props.account])

    return (
        <div className={'w-100 d-flex flex-column align-items-center'} style={{padding:40}}>
            <div className={"w-100 d-none d-sm-block"}>
                <div className={'w-100 d-flex justify-content-between'} >
                    <p
                        style={{
                            color:'rgba(9,9,121,1)',
                            fontWeight:'bold',
                            fontSize:20
                        }}
                    >
                        My Collection
                    </p>
                    <Button
                        onMouseEnter={ () => setHov1(true) }
                        onMouseLeave={ () => setHov1(false) }
                        style={(hov1)? button_hov : button }
                        onClick={()=>{
                            let url =''

                            console.log(props.networkId)
                            if(props.networkId == 1 || 137){
                                url = 'https://opensea.io/collection/eclipse-nft-v2';


                            }
                            else if (props.networkId == 4){
                                url = 'https://testnets.opensea.io/collection/eclipse-nft-v4'
                            }
                            else{
                                console.log('network not supported')
                            }
                            window.open(url, '_blank');
                        }}
                    >
                        Buy & Sell NFTs
                    </Button>
                </div>
            </div>
            <div className={' w-100 d-sm-none'}>
                <p
                    style={{
                        textAlign:"left",
                        color:'rgba(9,9,121,1)',
                        fontWeight:'bold',
                        fontSize:20
                    }}
                >
                    My Collection
                </p>
                <Button
                    onMouseEnter={ () => setHov1(true) }
                    onMouseLeave={ () => setHov1(false) }
                    style={(hov1)? button_mobile_hov : button_mobile}
                    onClick={()=>{
                        let url =''

                        console.log(props.networkId)
                        if(props.networkId == 1 || 137){
                            url = 'https://opensea.io/collection/eclipse-nft-v2';


                        }
                        else if (props.networkId == 4){
                           url = 'https://testnets.opensea.io/collection/eclipse-nft-v4'
                        }
                        else{
                            console.log('network not supported')
                        }
                        window.open(url, '_blank');
                    }}
                >
                    Buy & Sell NFTs
                </Button>
            </div>
            { (!props.account)?
                <>
                    <Row style={{margin:40}}>
                        <Col sm={1}></Col>
                        <Col className={'d-flex justify-content-center'} >
                            <p style={{marginTop:100, color:'black', fontSize:18, fontWeight:'bold'}}> Connect to view NFTs</p>
                        </Col>
                        <Col sm={1}></Col>
                    </Row>
                    <Row style={{height:16}}></Row>
                </>

                :
                <></>
            }
            {(props.account && (songs.length > 0)) ?
                <Row>
                    {
                        Array.from({ length:songs.length }).map((_, index) => (
                            <Col style={{marginTop:15}}>
                                <TrackCard
                                    key={index}
                                    image={songs[index].image}
                                    title={songs[index].title}
                                    artist={songs[index].artist}
                                    totalPlays={songs[index].totalPlays}
                                    publicReleaseDate={songs[index].publicReleaseDate}
                                    tokenId={songs[index].tokenId}
                                    metadataHash={songs[index].metadataHash}
                                    web3={props.web3}
                                    account={props.account}
                                />
                            </Col>
                        ))}
                </Row>
                :
                <></>
            }
            {(props.account && (songs.length == 0)) ?
                <>
                    <Row style={{margin:40}}>
                        <Col sm={1}></Col>
                        <Col style={{padding:20}}>
                            <p style={{marginTop:30, color:'black', fontSize:18, fontWeight:'bold'}}>
                                No NFTs found.
                            </p>
                        </Col>
                        <Col sm={1}></Col>
                    </Row>
                    <Row style={{height:46}}></Row>
                </>
                :
                <></>
            }
        </div>

    );
}

const playbutton ={
    color:'black',
    fontWeight:'bold',
}

const playbutton_hov ={
    color:'white',
    fontWeight:'bold',
}
const button ={
    height:45,
    width:200,
    backgroundColor:'#7900ff',
    borderColor:'#7900ff',
    color:'white',
    fontWeight:'bold',
    fontSize:20
}

const button_hov ={
    height:45,
    width:200,
    backgroundColor:'rgb(35,10,151)',
    borderColor:'rgb(35,10,151)',
    color:'white',
    fontWeight:'bold',
    fontSize:20
}

const button_mobile ={
    height:45,
    width:'100%',
    backgroundColor:'#7900ff',
    borderColor:'#7900ff',
    color:'white',
    fontWeight:'bold',
    fontSize:20
}

const button_mobile_hov ={
    height:45,
    width:'100%',
    backgroundColor:'rgb(35,10,151)',
    borderColor:'rgb(35,10,151)',
    color:'white',
    fontWeight:'bold',
    fontSize:20
}

const titleText = {
    color:'#7900ff',
    fontWeight:'bold',
    fontSize:20,
    marginLeft:15,
    marginTop:0
}
