// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCUWiTSHnV9h5DNdOHR4d6Ddkt5Sd7EM50",
    authDomain: "eclipse-d682f.firebaseapp.com",
    projectId: "eclipse-d682f",
    storageBucket: "eclipse-d682f.appspot.com",
    messagingSenderId: "907567804658",
    appId: "1:907567804658:web:4c9fb718370f8fc8f6e06b",
    measurementId: "G-QVDTTXD9WF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const functions = getFunctions(app);
const db = getFirestore(app);

export {app, storage, functions, db};
