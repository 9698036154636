import {useState,useRef} from "react";
import {Button, Col, Form, Modal, ProgressBar, Row, Alert} from "react-bootstrap";
import {BsImageAlt, BsMusicNoteBeamed, BsCheckCircle, BsFillCameraVideoFill} from "react-icons/bs";
import * as React from "react";
import ConnectButton from "../../components/ConnectButton";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { addDoc, collection } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { storage, functions, db } from "../../firebase";

import {chainId, contractAddress, ABI, chainData} from "../../contractArtifacts/contractDetails";
import { css } from "@emotion/react";
import GridLoader from "react-spinners/GridLoader";
import PublicNFT from "./PublicNFT";
import PrivateNFT from "./PrivateNFT";
import TermsAndConditions from "../../components/TermsAndConditions";


const createMetadata = httpsCallable(functions, 'createMetadata');





export default function Mint(props) {

    const [trackName, setTrackName] = useState();
    const [artistName, setArtistName] = useState();
    const [albumName, setAlbumName] = useState();
    const [playsBeforeLock, setPlaysBeforeLock] = useState();
    const [publicReleaseDate, setPublicReleaseDate] = useState();

    const [hov1, setHov1] = useState(false);
    const [hov2, setHov2] = useState(false);
    const [hov3, setHov3] = useState(false);
    const [hov4, setHov4] = useState(false);

    const [progress1, setProgress1] = useState(0);
    const [progress2, setProgress2] = useState(0);

    const [file1URL, setFile1URL] = useState();
    const [file2URL, setFile2URL] = useState();

    const [file1Name, setFile1Name] = useState();
    const [file2Name, setFile2Name] = useState();

    const [file1Type, setFile1Type] = useState();
    const [file2Type, setFile2Type] = useState();

    const hiddenFileInput1 = useRef(null);
    const hiddenFileInput2 = useRef(null);

    const [show, setShow] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [variant, setVariant] = useState("danger");

    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState('#7900ff');

    const mint = async () => {
        // Step 0: Check image for size and file type
        // Step 1: Upload to IPFS and get metadata url

        if(props.web3 && props.account){
            try{
                setLoading(true);
                let metadata = (await createMetadata({
                    imgSrc: file2URL ? file2URL : '',
                    name: trackName ? trackName : '',
                    artist: artistName ? artistName : '',
                    artistAddress: props.account,
                    track: trackName ? trackName : '',
                    totalPlays: playsBeforeLock ? playsBeforeLock : '',
                    playsRemaining: playsBeforeLock ? playsBeforeLock : '',
                    privateDataType: (file1Type == 'video/mp4' || 'video/quicktime') ? 'Video' : 'Audio'
                })).data;

                console.log('metadata: ', metadata);

                //Step 2: Call mint function on NFT contract
                let contract = chainData.filter(item => item.chainId === props.networkId)[0].erc721Contract;
                let eclipseContract = new props.web3.eth.Contract(ABI,contract);

                eclipseContract.methods.createToken( metadata.metadataURI ,playsBeforeLock).send({from: props.account}, function(error,result){
                    if(error){
                        setLoading(false)
                    }
                })
                    .on('receipt', async function(receipt) {
                        // receipt example
                        console.log(receipt);

                        //step3: upload public NFT metadata to cloud firestore
                        const docRef = await addDoc(collection(db, "nft"), {
                            imgSrc: file2URL ? file2URL : '',
                            metadataHash: metadata.metadataURI,
                            name: trackName ? trackName : '',
                            artist: artistName ? artistName : '',
                            artistAddress: props.account,
                            track: trackName ? trackName : '',
                            album: albumName? albumName : '',
                            totalPlays: playsBeforeLock ? playsBeforeLock : '',
                            publicReleaseDate: publicReleaseDate ? publicReleaseDate : '',
                            chainId: chainId,
                            privateDataType: (file1Type == 'video/mp4' || 'video/quicktime') ? 'Video' : 'Audio'
                        });

                        console.log("Document written with ID: ", docRef.id);

                        //step4: upload private NFT metadata to cloud firestore
                        const docRef2 = await addDoc(collection(db, "nft_secret"), {
                            audio: file1URL ? file1URL : '',
                            imgSrc: file2URL ? file2URL : '',
                            metadataHash: metadata.metadataURI,
                            name: trackName ? trackName : '',
                            artist: artistName ? artistName : '',
                            artistAddress: props.account,
                            track: trackName ? trackName : '',
                            album: albumName? albumName : '',
                            totalPlays: playsBeforeLock ? playsBeforeLock : '',
                            publicReleaseDate: publicReleaseDate ? publicReleaseDate : '',
                            chainId: chainId,
                            privateDataType: (file1Type == 'video/mp4' || 'video/quicktime') ? 'Video' : 'Audio'
                        });

                        console.log("Document written with ID: ", docRef2.id);

                        //step 5: show success or failure and redirect to artist page

                        setAlertText("Success! Your minted NFT will now show up in your Artist page")
                        setVariant("success")
                        setLoading(false)
                        setShowAlert(true)
                    })

            }
            catch(e){
                console.log(e);
            }
        }
        else{
            setAlertText("Connect your wallet to mint.")
            setVariant("danger")
        }

    }

    const handleClick1= event => {
        hiddenFileInput1.current.click();
    };

    const handleClick2= event => {
        hiddenFileInput2.current.click();
    };

    const onFileChange1 = async (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        uploadFiles1(file);

    }

    const onFileChange2 = async (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        uploadFiles2(file);

    }

    const uploadFiles1 = (file) => {

        if (!file) return;

        console.log(file.type);
        if(
            file.type === 'audio/mpeg'||
            file.type === 'audio/ogg' ||
            file.type === 'video/mp4' ||
            file.type === 'video/quicktime'
        )
        {
            if(Number(file.size) > 287600000){
                setAlertText('File too large. Max file size is 287.6MB')
                setShowAlert(true)
            }
            else {

                console.log(file.size);

                const sotrageRef = ref(storage, `files/${file.name}`);
                const uploadTask = uploadBytesResumable(sotrageRef, file);
                setFile1Name(file.name);
                setFile1Type(file.type);
                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        const prog = Math.round(
                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        );
                        setProgress1(prog);
                    },
                    (error) => console.log(error),
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                            console.log("File available at", downloadURL);
                            setFile1URL(downloadURL);
                        });
                    }
                );
            }
        }
        else{
            setAlertText('Wrong file type. Supported types: mpeg, ogg, mp4, mov/quicktime')
            setShowAlert(true)
        }

    };

    const uploadFiles2 = (file) => {

        if (!file) return;
        console.log(file.type)
        if(file.type === 'image/jpeg'|| file.type === 'image/png' ){
            const sotrageRef = ref(storage, `files/${file.name}`);
            const uploadTask = uploadBytesResumable(sotrageRef, file);
            setFile2Name(file.name);
            setFile2Type(file.type);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const prog = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    setProgress2(prog);
                },
                (error) => console.log(error),
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        console.log("File available at", downloadURL);
                        setFile2URL(downloadURL);
                    });
                }
            );

        }
        else{
            setAlertText('Wrong file type. Supported types: jpeg, png')
            setShowAlert(true)
        }
    };

    return (
        <div className={'w-100 d-flex flex-column align-items-center'}>
            <div style={{width:'100%', textAlign:'center' }}>
                <p
                    style={{
                        marginTop:40,
                        color:'rgba(9,9,121,1)',
                        fontWeight:'bold',
                        fontSize:40,
                    }}
                >
                    NFT Minting Portal
                </p>
            </div>

            <div className={'p-5 Mint'} style={{minWidth:330}}>

                {/* 1)  Connect wallet */}
                <div style={{marginTop:20}}>
                    <div className={'d-flex'}>
                        <div
                            className={'d-flex flex-column align-items-center'}
                            style={numbers}
                        >
                            <p style={{
                                marginTop:-2
                            }}>1</p>
                        </div>
                        <p style={stepText}>Connect Wallet</p>
                    </div>
                    <div>
                        <ConnectButton
                            web3={props.web3}
                            account={props.account}
                            setWeb3={(w,a) => props.setWeb3(w,a)}
                            hovStyle={hovStyleButton}
                            normalStyle={normalStyleButton}
                        />
                    </div>
                </div>

                {/* 2)  Upload title and music/image files */}
                <div style={{marginTop:40}}>
                    <div className={'d-flex'}>
                        <div
                            className={'d-flex flex-column align-items-center'}
                            style={numbers}
                        >
                            <p style={{
                                marginTop:-2
                            }}>2</p>
                        </div>
                        <p style={stepText}>Configure</p>
                    </div>
                    <div>
                        <p style={labelText}>NFT Title</p>
                        <Form.Control
                            style={{marginTop:-15, width:300}}
                            value={trackName}
                            onChange={(e) => setTrackName(e.target.value)}/>

                        <p style={labelText}>Artist / Creator</p>
                        <Form.Control
                            style={{marginTop:-15, width:300}}
                            value={artistName}
                            onChange={(e) => setArtistName(e.target.value)}/>

                        <p style={labelText}>Number of plays before NFT is burned</p>
                        <Form.Control
                            style={{marginTop:-10, width:300}}
                            value={playsBeforeLock}
                            onChange={(e) => setPlaysBeforeLock(e.target.value)}
                        />



                        <div className={'d-flex'} style={{marginTop:10,fontSize:100,}}>

                            <div>
                                <p style={labelText}>Public NFT image</p>
                                <div className={'d-flex justify-content-center align-items-center'}
                                     onMouseEnter={ () => setHov2(true) }
                                     onMouseLeave={ () => setHov2(false) }
                                     style={(hov2)? dashedBorderHov : dashedBorder }
                                     onClick={handleClick2}
                                >
                                    <BsImageAlt />
                                </div>
                            </div>
                            <div>
                                <p style={labelText}>Private NFT Media</p>
                                <div className={'d-flex flex-column justify-content-center align-items-center'}>
                                    <div className={'d-flex justify-content-center align-items-center'}
                                         onMouseEnter={ () => setHov1(true) }
                                         onMouseLeave={ () => setHov1(false) }
                                         style={(hov1)? dashedBorderHov1 : dashedBorder1 }
                                         onClick={handleClick1}
                                    >
                                        <BsMusicNoteBeamed style={{fontSize:'40px',marginRight:10}}/>
                                        <p style={{marginTop:'10px',fontSize:'20px', fontWeight:'bold'}}> or </p>
                                        <BsFillCameraVideoFill style={{fontSize:'40px' ,marginLeft:10}}/>
                                    </div>
                                </div>
                            </div>
                            <input
                                className={'.d-none'}
                                type="file"
                                ref={hiddenFileInput1}
                                onChange={onFileChange1}
                                style={{display: 'none'}}
                            />
                            <input
                                type="file"
                                ref={hiddenFileInput2}
                                onChange={onFileChange2}
                                style={{display: 'none'}}
                            />
                        </div>
                        {
                            (file1Name)?
                                <div className={'mt-4'}>
                                    <div className={'d-flex m-2'}>
                                        <p style={{fontWeight:"bold",color:'rgba(9,9,121,1)',}}>{file1Name}</p>
                                        {(progress1 == 100)?
                                            <BsCheckCircle style={{marginLeft:15, color:'green', fontSize:26}}/>
                                            :
                                            <></>
                                        }
                                    </div>
                                    { (progress1 < 100)?
                                        <ProgressBar now={progress1} />
                                        :
                                        <></>
                                    }
                                </div>
                                :
                                <></>
                        }
                        {
                            (file2Name)?
                                <>
                                    <div className={'d-flex m-2'}>
                                        <p style={{fontWeight:"bold",color:'rgba(9,9,121,1)',}}>{file2Name}</p>
                                        {(progress2 == 100)?
                                            <BsCheckCircle style={{marginLeft:15,color:'green', fontSize:26}}/>
                                            :
                                            <></>
                                        }
                                    </div>
                                    { (progress2 < 100)?
                                        <ProgressBar now={progress2} />
                                        :
                                        <></>
                                    }
                                </>
                                :
                                <></>
                        }

                    </div>
                </div>


                {/* 3)  Mint Music NFT*/}
                <div style={{marginTop:40}}>
                    <div className={'d-flex'}>
                        <div
                            className={'d-flex flex-column align-items-center'}
                            style={numbers}
                        >
                            <p style={{
                                marginTop:-2
                            }}>3</p>
                        </div>
                        <p style={stepText}>Mint NFT</p>
                    </div>
                    <div >
                        <div style={{marginTop:20}} >
                            {
                                (file1Name && file2Name && trackName && artistName)?
                                    <Button variant="outline-light"
                                            onMouseEnter={ () => setHov4(true) }
                                            onMouseLeave={ () => setHov4(false) }
                                            style={(hov4)? hovStyleButton : normalStyleButton }
                                            onClick={() => setShow(true)}>Preview NFT</Button>
                                    :
                                    <></>
                            }
                        </div>
                        <div style={{marginTop:20}}>
                            <Button
                                variant="outline-light"
                                onMouseEnter={ () => setHov3(true) }
                                onMouseLeave={ () => setHov3(false) }
                                style={(hov3)? hovStyleButton : normalStyleButton }
                                onClick={mint}
                            >
                                MINT NFT
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{height:200}}></div>
            <Modal size={'lg'} show={show} fullscreen={'md-down'} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                </Modal.Header>

                <Modal.Body>
                    <Row style={{padding:20}}>
                        <Col className={'d-flex flex-column align-items-center'}>
                            <p style={titleText}>Public View</p>
                            <PublicNFT
                                title={trackName}
                                artist={artistName}
                                image={file2URL}
                                totalPlays={playsBeforeLock}
                            />
                        </Col>
                        <Col className={'d-flex flex-column align-items-center'}>
                            <p style={titleText}>Private View</p>
                            <PrivateNFT
                                title={trackName}
                                artist={artistName}
                                image={file2URL}
                                audio={file1URL}
                                totalPlays={playsBeforeLock}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            {
                (showAlert)?
                    <Alert
                        variant={variant}
                        onClose={() => setShowAlert(false)}
                        dismissible
                        style={{position:'fixed',top:20, right:20, zIndex:40000}}
                    >
                        <p>
                            {alertText}
                        </p>
                    </Alert>
                    :
                    <></>
            }
            {
                (!props.tsAndCs)?
                    <TermsAndConditions accepted={() => {
                        props.setTsAndCs();
                    }}/>
                    :
                    <></>
            }
            <GridLoader color={color} loading={loading} css={override} size={50} />
        </div>
    );
}

const stepText = {
    color:'#7900ff',
    fontWeight:'bold',
    fontSize:28,
    marginLeft:15,
    marginTop:0
}

const labelText = {
    color:'#7900ff',
    fontWeight:'bold',
    fontSize:18,
    marginRight:20,
    marginTop:10
}

const fanText = {
    color:'#7900ff',
    fontWeight:'bold',
    fontSize:50
}
const titleText = {
    color:'#7900ff',
    fontWeight:'bold',
    fontSize:20,
    marginLeft:15,
    marginTop:0
}

const numbers = {
    width:'35px',
    height:'35px',
    backgroundColor:'#7900ff',
    color:'white',
    fontSize:25,
    fontWeight:'bold',
    borderRadius:'100%',
}

const dashedBorder = {
    height:150,
    width:150,
    borderRadius:20,
    borderWidth:4,
    borderStyle:"dashed",
    color:'#7900ff'
}

const dashedBorderHov = {
    height:150,
    width:150,
    borderRadius:20,
    borderWidth:4,
    borderStyle:"dashed",
    color:'#4609c2'
}

const dashedBorder1 = {
    height:150,
    width:150,
    borderRadius:20,
    borderWidth:4,
    borderStyle:"dashed",
    color:'#7900ff'
}

const dashedBorderHov1 = {
    height:150,
    width:150,
    borderRadius:20,
    borderWidth:4,
    borderStyle:"dashed",
    color:'#4609c2'
}


const hovStyleButton= {
    width:200,
    height:50,
    fontSize: 20,
    fontWeight: 'bold',
    color:'rgba(9,9,121,1)',
    borderWidth:'2px',
    borderColor:'rgba(9,9,121,1)',
}

const normalStyleButton={
    width:200,
    height:50,
    fontSize: 20,
    fontWeight: 'bold',
    color:'#7900ff',
    borderWidth:'2px',
    borderColor:'#7900ff',
}

const override = css`
  position: fixed;
  top:40%;
  display: block;
  margin: 0 auto;
  border-color: #7900ff;
`;